<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="mycoiner">
          <div class="row">
            <div class="col-12 col-lg-8 col-xl-9">
              <div class="mycoiner__first">
                <ui-title tag="6" class="tc-white lh-125">
                  {{ cc.title }}
                </ui-title>
              </div>
            </div>
            <div class="col-12 col-lg-4 col-xl-3">
              <div class="mycoiner__last">
                <ui-btn :data="cc.btn[0]" class="btn-s2 btn-w-200 mx-auto" color="night" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import uiTitle from '@/components/UI/Title'
import uiBtn from '@/components/UI/Btn'
export default {
  components: {
    uiTitle, uiBtn
  },
  computed: {
    cc () {
      return this.$t('components.banners.mycoiner')
    }
  }
}
</script>
