<template>
  <main class="main" data-view="about">
    <section class="section" id="s1">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-5 col-xl-4 offset-xl-1">
            <div v-if="$store.state.ww < 768">
              <ui-text class="tc-waikawa tw-500 lh-35 ta-center">
                {{ cc.s1.pre }}
              </ui-text>
              <ui-title tag="1" class="lh-125 ta-center">
                {{ cc.s1.title }}
              </ui-title>
            </div>
            <div class="img img--about img--about-s1">
              <img src="/img/pages/about/img-s1-1.png" alt="img-s1-1" />
            </div>
          </div>
          <div class="col-12 col-md-7 col-lg-6 offset-lg-1 col-xl-5">
            <div v-if="$store.state.ww >= 768">
              <ui-text tag="3" class="tc-waikawa tw-500 lh-35">
                {{ cc.s1.pre }}
              </ui-text>
              <ui-title tag="1" class="lh-125 mb-md-4">
                {{ cc.s1.title }}
              </ui-title>
            </div>
            <ui-text class="tc-gray lh-35 mb-md-5 ta-center ta-md-start">
              {{ cc.s1.text }}
            </ui-text>
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="s2">
      <div class="container">
        <div class="row flex-column-reverse flex-md-row">
          <div class="col-12 col-lg-6">
            <div class="wrapper d-flex flex-column justify-content-center px-xl-5">
              <ui-text class="tc-gray lh-35 mb-4 mb-md-5 ta-center ta-md-start">
                {{ cc.s2.text }}
              </ui-text>
              <ui-link :data="cc.s2.link[0]" modifier="arrow-right" class="ts-16 ts-md-20 mx-auto mx-md-0" />
            </div>
          </div>
          <div class="col-12 col-lg-5 offset-lg-1">
            <div class="img img--about img--about-s2">
              <img src="/img/pages/about/img-s2-1.svg" alt="img-s2-1" />
              <img src="/img/pages/about/img-s2-2.svg" alt="img-s2-2" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="s3">
      <comp-banner-mycoiner />
    </section>
    <section class="section section--snow" id="s4">
      <comp-contact />
    </section>
  </main>
</template>
<script>
import compBannerMycoiner from '@/components/Banners/BannerMycoiner'
import compContact from '@/components/Contact'
import uiTitle from '@/components/UI/Title'
import uiText from '@/components/UI/Text'
import uiLink from '@/components/UI/Link'
export default {
  components: {
    compBannerMycoiner, compContact, uiTitle, uiText, uiLink
  },
  computed: {
    cc () {
      return this.$t('pages.about')
    }
  },
  mounted () {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  },
  metaInfo () {
    const meta = this.$t('meta.about')
    return {
      titleTemplate: '%s' + (meta.title ? ' | ' + meta.title : ''),
      meta: [
        { name: 'description', content: meta.description },
        { property: 'og:description', content: meta.description }
      ]
    }
  }
}
</script>
